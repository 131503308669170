import React, { Component } from "react";
import { H1, H2 } from "../theme/typo";
import styled from "styled-components";
import ConvertKitForm from 'convertkit-react'

import ZapierForm from 'react-zapier-form'

import { Helmet } from 'react-helmet';

const title = 'Beta-testa nya versionen | Aktieutdelningar';
const desc = 'Anmäl dig för att vara en av de första som får testa nya versionen, utvecklad från grunden med bättre användarvänlighet och nya funktioner.';

const Main = styled.main`
  background-color: ${(props) => props.theme.lightgrey};
  margin: 0 auto;
  text-align: left;
  max-width: 70rem;
  height: auto;
  background: transparent;
  padding: 3rem 3rem 6rem;

  form {
    font-size: 16px;
    line-height: 1.776;

    input {
      margin-bottom: 10px;
      border: 2px #000 solid;
      border-radius: 4px;
      padding: 10px 12px;
      display: block;
    }

    button {
      background: rgba(0, 0, 255, 1);
      color: white;
      border: none;
      border-radius: 4px;
      padding: 10px 18px;
      cursor: pointer;
    }

    @media (max-width: 600px) {
      input,
      button {
        width: 100%;
      }
    }
  }
`;

const StyledH1 = styled(H1)`
  span {
    font-size: 4rem;
    display: block;
  }
`;

const StyledH2 = styled(H2)`
  margin: 4rem 0 1rem 0;
`;

const List = styled.ul`
  list-style-type: disc;
  margin-left: 30px;

  li {
    font-size: 18px;
    line-height: 1.776;
    padding-left: 6px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.776;
  span {
    font-size: 6rem;
    display: block;
    @media (min-width: 1024px) {
      font-size: 7rem;
    }
  }
`;

const Message = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
`;

const Small = styled.div`
  background: rgba(0, 0, 255, 0.04);
  padding: 5px 20px;
  margin: 40px 0;
  border-left: 4px rgba(0, 0, 255, 1) solid;

  p {
    margin: 10px 0;
    font-size: 14px;
    line-height: 1.776;
  }
`;

class Maintenance extends Component {
  render() {

    const formConfig = {
      formId: 1766231,
      template: 'minimal',
      namePlaceholder: 'Ditt namn',
      emailPlaceholder: 'Skriv in din e-postadress',
      submitText: 'Sign up',
      newTab: true,
      stack: true,
      submitText: 'Registrera mig'
    }

    return <>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={desc} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:url" content={window.location.href} />
          {/* <meta property="og:image" content={NextoryMetaImg} /> */}
          <meta name="twitter:title" content={title} />
        </Helmet>
        <Main>
          <div>
            <Paragraph>
              <a href="/">Tillbaka till Aktieutdelningar</a>
            </Paragraph>
            <StyledH1>
              <span role="img" aria-label="emoji">
                🚀
              </span>
              <br />
              Vill du vara med och testa nya Aktieutdelningar?
            </StyledH1>
            <Paragraph>
              Vi börjar närma oss mållinjen med utvecklingen av nästa
              generation av Aktieutdelningar, byggd på nytt från grunden med
              nya häftiga funktioner och avsevärt bättre användarvänlighet.
              Vi kommer gå igenom i detalj vad detta innebär framöver, men
              några uppdateringar är:
            </Paragraph>
            <List>
              <li>Byggd på ny och modern teknik.</li>
              <li>Stöd för oändligt med enheter.</li>
              <li>
                Ny och fräsch design med förbättrad användarvänlighet.
              </li>
              <li>Utökad statistik över innehavet.</li>
              <li>Bättre stöd för värdepapper som saknas.</li>
              <li>... och mycket mer.</li>
            </List>
            <br />
            <ZapierForm action="https://hooks.zapier.com/hooks/catch/8990080/oeb2ptq/">
              {({ error, loading, success }) => {
                return <div>
                    {!success && !loading && <div>
                          <input type="text" name="First Name" placeholder="Förnamn" />
                          <input type="email" name="Email" placeholder="E-mail" />
                          <button>Anmäl mig</button>
                        </div>}
                    {loading && <div>Laddar...</div>}
                    {error && <div>
                        Något gick fel. Testa igen!
                      </div>}
                    {success && <div>Tack för att du anmälde dig!</div>}
                  </div>;
              }}
            </ZapierForm>
            <Small>
              <p>
                Vi kommer <strong>inte</strong> använda dina uppgifter till något annat än att kontakta dig gällande Aktieutdelningar. Vi skickar inte heller ut någon form av spam.
              </p>
              <p>
                I samband med att du fyller i formuläret så accepterar du
                dock att vi hanterar dina uppgifter i enlighet med gällande
                lagstiftning. Vill du bli borttagen så finns det en länk i
                mailet, men du kan även kontakta oss så löser vi det!
              </p>
              <p>
                Formuläret styrs genom <a href="https://emailoctopus.com/?urli=Gt6wc">
                  EmailOctopus
                </a>.
              </p>
            </Small>
            <StyledH2>När kommer ni lansera?</StyledH2>
            <Paragraph>
              Vi har som mål att lansera under Q4 och när det väl blir dags
              kommer vi att kontakta de som skriver upp sig på väntelistan
              via mail.
            </Paragraph>
            <Paragraph>
              Som ett första steg kommer vi att höra av oss för att testa en
              beta-version. Det är helt valfritt att delta, men er feedback
              snabbar upp processen att lansera en skarp version.
            </Paragraph>
            <StyledH2>Vad kommer hända med denna sidan?</StyledH2>
            <Paragraph>
              Den nuvarande version av Aktieutdelningar kommer att finnas
              kvar efter lanseringen, så ingen data kommer att försvinna för
              er som önskar fortsätta använda den.
            </Paragraph>
            <StyledH2>Har du några övrigafrågor?</StyledH2>
            <Paragraph>
              Skicka ett mail till <a href="mailto:patrik@aktieutdelningar.co">
                patrik@aktieutdelningar.co
              </a> eller skriv i chattwidgeten nere till höger så svarar jag så snart jag kan! 😀
            </Paragraph>
          </div>
        </Main>
      </>;
  }
}

export default Maintenance;
