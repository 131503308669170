import React from 'react';
import PropTypes from 'prop-types';

import { ModalContainer, Close, H2 } from './ModalStyle';

class Modal extends React.PureComponent {
  static propTypes = {
    handleClick: PropTypes.func,
  };

  render() {
    return (
      <ModalContainer>
        <aside onClick={this.props.handleClick} />
        <div>
          <Close onClick={this.props.handleClick}>✖</Close>
          <H2>
            Vanliga frågor och svar
            <span role="img" aria-label="emoji hum">
              🤔
            </span>
          </H2>

          <p>
            <strong>
              Sparas min data?
              <br />
            </strong>
            Din portfölj sparas bara i din webbläsares{' '}
            <a
              rel="nofollow"
              href="https://www.w3schools.com/html/html5_webstorage.asp"
              target="blank"
            >
              Localstorage
            </a>
            , detta för att du ska slippa skapa ett konto. Kortfattat, det är bara du och din
            webbläsare som kan se din portfölj. Vill du se portföljen i både din dator, andra
            datorer och telefoner så måste du skapa den i respektive enhet.
          </p>
          <p>
            <strong>
              Hur hämtas aktiedata?
              <br />
            </strong>
            Aktiedata hämtas från Avanza varje gång du lägger till en ny aktie i din portfölj. Inte
            när du justerar antalet av respektive innehav. Så för att uppdatera din portföljdata,
            klicka på knappen "Uppdatera aktiekurser"
          </p>
          <p>
            <strong>
              Utdelningen stämmer inte/saknas?
              <br />
            </strong>
            Då all utdelningsdata hämtas från Avanza för det nuvarande året, kan det ibland hända
            att data inte stämmer (splittar av bolag) eller att Avanza inte har datan (amerikanska
            aktier). Då finns möjligheten att editera utdelningar för respektive aktie genom att
            klicka på den lilla pennan bredvid ditt innehav.
          </p>
          <p>
            <strong>
              År 2020 då?
              <br />
            </strong>
            All utdelningsdata hämtas direkt från Avanza och bolagens utdelningar
            blir kända i samband med bolagens bokslut som sedan Avanza lägger in i sitt system. Därför
            kan det ta lite tid vid varje nyår innan utdelningarna syns för dig som använder tjänsten.
            Om du vet att att Avanza har lagt in datum för 2020 så kan du testa att klicka på knappen "Uppdatera aktiekurser" för att läsa in dem.
          </p>
          <p>
            <strong>
              Använder hemsidan cookies?
              <br />
            </strong>
            Webappen använder sig av Google Analytics för att spåra trafik. Däremot så sparas inte
            data som har med enskild användare att göra.
          </p>
          <p>
            <strong>
              Vem skapade denna webbapp?
              <br />
            </strong>
            Dick Törnfeldt ligger bakom appen, men har sedan en tid tillbaka stängt ner tjänsten på
            grund av tidsbrist. Om du uppskattar tiden som har lagts ner för att utveckla verktyget
            så kontakta honom gärna och berätta det. Avstå från att skicka felanmälningar dock, eftersom
            han inte längre förvaltar och driver tjänsten. Dick går att nå via mail på{' '}
            <a href="mailto:dick@pigment.se">dick@pigment.se</a> eller på{' '}
            <a rel="nofollow" href="https://twitter.com/dicktornfeldt" target="blank">
              {' '}
              Twitter.
            </a>
          </p>
          <p>
            <strong>
              Varför funkar inte ...XXX?
              <br />
            </strong>
            Appen förvaltas numera av {' '}
            <a href="https://patrikarvidsson.com">Patrik Arvidsson</a> som ett sidoprojekt. Tiden som kan läggas
            ner är inte oändlig, men om det uppstår problem med användarupplevelsen så skicka ett mail
            till{' '}
            <a href="mailto:patrik@aktieutdelningar.co">patrik@aktieutdelningar.co</a> så ska jag kika på det snarast!
          </p>
        </div>
      </ModalContainer>
    );
  }
}

export default Modal;
